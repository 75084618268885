import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import Container from '../components/Layout/container';
import { MainSection100, MainSection50 } from '../components/Layout/sections';
import NewLayout from '../components/new_layout';
import LinkButton from '../components/v2023/UI/LinkButton/link-button';
import { SocialMediaTecalis } from '../components/v2024/social-media';
import Toolbar from "../components/v2023/UI/Card/toolbar"

const ThankYouBofu = ({ data, pageContext, location }) => {
	const page = data.page;
	const lang = pageContext.langKey

	return (
		<NewLayout pageContext={pageContext} noHeader thinFooter>
			<div className="thankyou">
				<MainSection100 sectionType="color" sectionColor="gray">
					<Container section="100">
						<GatsbyImage
							alt={page.logo_tecalis.alternativeText}
							image={getImage(page.logo_tecalis.localFile)}
						/>
						<div className="container__text">
							<GatsbyImage
								alt={page.hero.image.alternativeText}
								image={getImage(page.hero.image.localFile)}
							/>
							<div className="container__text__text">
								<h1 className='merriweather'>{page.hero.title}</h1>
								<ReactMarkdown children={page.hero.description} rehypePlugins={[rehypeRaw]} />
								<LinkButton button={page.hero.button} className="button button--xl" />
							</div>
						</div>
						<Toolbar lang={lang} url={location.href} />
					</Container>
				</MainSection100>
				<MainSection50 sectionSize="md">
					<Container>
						<Container section="5050" container50>
							<ReactMarkdown children={page.request_a_demo.title_underline} rehypePlugins={[rehypeRaw]} />
							<div className="container__text">
								<ReactMarkdown children={page.request_a_demo.description} rehypePlugins={[rehypeRaw]} />
							</div>
							<LinkButton
								className='link--sm'
								button={page.request_a_demo.button}
								extras={<i className="icon-arrow-right"></i>}
							/>
						</Container>
						<Container section="5050" container50>
							<GatsbyImage
								alt={page.request_a_demo.image.alternativeText}
								image={getImage(page.request_a_demo.image.localFile)}
							/>
						</Container>
					</Container>
				</MainSection50>
				<MainSection100>
					<Container section="100">
						<SocialMediaTecalis secondary title={page.titulo_redes}/>
					</Container>
				</MainSection100>
			</div>
		</NewLayout>
	);
};

export default ThankYouBofu;

export const agradecimientosBofuQuery = graphql`
	query ($id: String!, $langKey: String!) {
		page: strapiAgradecimientosBofus(locale: { eq: $langKey }, id: { eq: $id }) {
			logo_tecalis {
				name
				alternativeText
				localFile {
					childImageSharp {
						gatsbyImageData(width: 147, placeholder: BLURRED, formats: [WEBP])
					}
				}
			}
			hero {
				title
				description
				button {
					text
					url
					blank
				}
				image {
					name
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(width: 164, placeholder: BLURRED, formats: [WEBP])
						}
					}
				}
			}
			request_a_demo {
				title_underline
				description
				button {
					text
					url
					blank
				}
				image {
					name
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(width: 608, placeholder: BLURRED, formats: [WEBP])
						}
					}
				}
			}
			titulo_redes
		}
	}
`;
